<template>
  <div id="shoppingHistory">
    <div style="display: flex;justify-content: space-between">
        <h5>门店订单</h5>
    </div>
    <br>
    <div class="searchForm">
        <div>
            会员姓名： <el-input size="small" v-model="search_form.member_name" placeholder="请输入会员姓名"  style="width:150px;"></el-input>
        </div>
        <div>
            会员地址： <el-select v-model="search_form.member_address" placeholder="请选择" size="small">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.label">
                            </el-option>
                        </el-select>
        </div>
        <div>
            会员手机号： <el-input size="small" v-model="search_form.member_phone" placeholder="请输入会员手机号"  style="width:150px;"></el-input>
        </div>
        <div>
            下单人员： <el-input size="small" v-model="search_form.place_order_person" placeholder="请输入下单人员姓名"  style="width:150px;"></el-input>
        </div>
        <div>
            添加时间：  <el-date-picker
                size="small"
                v-model="search_form.time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
        </el-date-picker>
        </div>
        <div>
            <el-button type="primary" size="small" @click="search">检索</el-button>
            <el-button size="small" @click="clearSearch">重置/检索</el-button>
        </div>
        <br>
        <el-button style="float: right;" type="primary" size="small" @click="downlode">下载</el-button>
        <div style="clear: both"></div>
    </div>

    <br>

    <el-table
        :data="orderList"
        style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
             <el-table :data="props.row.data"  border style="width: 100%">
    					   <el-table-column  prop="good_name"  label="商品名称" align="center"> </el-table-column>
                 <el-table-column   label="商品图片" align="center">
                   <template slot-scope="scope">
                      <img :src="scope.row.good_img" alt="" style="width: 100px;height: 100px;">
                   </template>
                 </el-table-column>
    					   <el-table-column   label="商品单价" align="center">
                    <template slot-scope="scope">
                        {{scope.row.good_price}}元/{{scope.row.unit}}
                    </template>
                 </el-table-column>
                   <el-table-column  prop="num"  label="数量" align="center"> </el-table-column>
    					   <el-table-column   label="规格" align="center">
                   <template slot-scope="scope">
                      {{scope.row.good_spec?scope.row.good_spec:'无'}}
                   </template>
                 </el-table-column>
    					   <el-table-column  prop="total"  label="小计" align="center">  </el-table-column>

    				  </el-table>
          </template>
        </el-table-column>
         <el-table-column  prop="order_number"  label="订单编号" > </el-table-column>
    	 <el-table-column  prop="member_name"  label="会员姓名" > </el-table-column>
    	<el-table-column  prop="member_phone"  label="会员手机号" > </el-table-column>
       <el-table-column  prop="member_address"  label="会员地址" > </el-table-column>
      <el-table-column label="添加时间" width="180"  align="center" >
        <template slot-scope="scope">
           <div>
             {{$getTime(scope.row.add_time)}}
           </div>
        </template>
      </el-table-column>
        <el-table-column label="下单店员"  prop="place_order_person"> </el-table-column>
        <el-table-column label="总计"  prop="sum"> </el-table-column>
      </el-table>
    <div class="page">
        <el-pagination
                background
                @size-change="pageChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page_totle">
        </el-pagination>
    </div>

  </div>
</template>

<script>
  export default{
    data(){
      return {
        search_form:{
          member_name:'',
          member_address:'',
          member_phone:'',
          place_order_person:'',
          time:[],
          start_time:'',
          end_time:''
        },
        options:[],
        page:1,
        pageSize:20,
        orderList:[],
        page_totle:0,
      }
    },
    mounted(){
      document.title = '门店订单'
      this.getAddress()
      this.getMemberShoppingHistory()
    },
    methods:{
      pageChange(pageSize){
          this.page=1
          this.pageSize=pageSize
          this.getMemberShoppingHistory()
      },
      handleCurrentChange(page){
          this.page=page
          this.getMemberShoppingHistory()
      },
      getMemberShoppingHistory(){
        let _this=this
        _this.search_form.page=_this.page
        _this.search_form.pageSize=_this.pageSize
         this.$post('/pc/member/getPlaceOrder',_this.search_form,function(res){
              let result = res.data
              if(result.code==200){
                  _this.orderList=result.data.res
                  _this.page_totle=result.data.count

              }
         })
      },
      getAddress(){
          let _this=this
          this.$post('/pc/member/getAddress',{},function (res) {
              let result = res.data
              if(result.code==200){
                  _this.$message.success(result.msg)
                  if(result.data){
                      let address = result.data.member_address
                      let len = address.length - 1
                      address = address.slice(0,len)
                      let arr = address.split(',')

                      for(let i in arr){
                          _this.options.push({
                              value:i,
                              label:arr[i]
                          })
                      }
                  }
              }
              if(result.code==401){
                 _this.$router.push({path:'/'})
              }
          })
      },
      search(){
          let _this = this
          if(this.search_form.time.length){
              this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
              this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
          }else{
              this.search_form.start_time = ''
              this.search_form.end_time = ''
          }
          _this.getMemberShoppingHistory()
      },
      clearSearch(){
              this.search_form.member_name=''
              this.search_form.member_address=''
              this.search_form.place_order_person = ''
              this.search_form.member_phone=''
              this.search_form.time=[]
              this.search_form.start_time=''
              this.search_form.end_time=''
              this.page=1
              this.pageSize=20
              this.getMemberShoppingHistory()
      },
      downlode(){
        let _this = this
         if(this.search_form.time.length){
             this.search_form.start_time = new Date(this.search_form.time[0]).getTime()/1000
             this.search_form.end_time = new Date(this.search_form.time[1]).getTime()/1000
         }else{
             this.search_form.start_time = ''
             this.search_form.end_time = ''
         }

         let cid = sessionStorage.getItem('cid')



         if(this.search_form.member_name){
             var member_name = '&member_name=' + this.search_form.member_name
         }else{
             var member_name = '&member_name='
         }

         if(this.search_form.member_address){
             var member_address = '&member_address=' + this.search_form.member_address
         }else{
            var member_address = '&member_address='
         }

         if(this.search_form.place_order_person){
             var place_order_person = '&place_order_person=' + this.search_form.place_order_person
         }else{
            var place_order_person = '&place_order_person='
         }

         if(this.search_form.member_phone){
             var member_phone = '&member_phone=' + this.search_form.member_phone
         }else{
            var  member_phone = '&member_phone='
         }

         if(this.search_form.start_time){
             var start_time = '&start_time=' + this.search_form.start_time
         }else{
             var start_time = '&start_time='
         }

         if(this.search_form.end_time){
             var end_time = '&end_time=' + this.search_form.end_time
         }else{
             var end_time = '&end_time='
         }
         var token = sessionStorage.getItem('token')
          token = '&token=' + token

       let url = `${_this.$domain}/pc/Excel/excelOrderList?cid=${cid}${member_name}${member_address}${member_phone}${start_time}${end_time}${place_order_person}${token}`
        location.href = url
      },
    }
  }
</script>

<style>
  #shoppingHistory .table .header>div{
    margin-right: 30px;

  }
</style>
